<template>
  <div class="">
    <input
      v-model="searchQuery"
      class="w-full py-1 px-2 border rounded-md shadow-sm mb-2"
      placeholder="Search..."
    >
    <div v-if="has_refresh" class="flex w-full justify-end">
      <i @click.prevent="$emit('refresh_data')" 
        class="fa-solid fa-rotate text-slate-200 cursor-pointer"></i>
    </div>
    <div v-if="is_grid" class="mt-2">
        <div class="grid gap-2" :class="[grid_cols]" >
            <div v-for="(item, index) in displayedItems" :key="index"  class="box mb-0 p-2">
                <div class="w-full has-text-centered">
                    <figure class="image m-auto" style="width:100%; height:auto;">
                        <img src="https://bulma.io/images/placeholders/64x64.png" style="width: 64px; height: auto; margin: auto">
                    </figure>
                    <p class="subtitle is-size-6 has-text-centered my-2 uppercase">{{item.name}}</p>
                </div>
            </div>
        </div>
    </div>
    <div v-else>
      <div v-if="entityType == 'user'">

      <ul class=" px-1">
        <li v-for="(item, index) in displayedItems" :key="index" class="p-1 flex border-b border-slate-200">
          <span>
            {{ item?.name }}
          </span>
          <span class="ml-auto">
            <span class="font-light is-size-7 is-uppercase">
                {{
                  item.type_id > 0
                    ? item.role?.name
                    : item.type_id == -1
                    ? "system_admin"
                    : "No role assigned"
                }}
              </span>
          </span>
          <a
            @click.prevent="$emit('open_item', item)"
            href=""
            class="ml-3"
          ><i class="fas fa-pencil-alt"></i></a>
        </li>
      </ul>


      </div>
      <ul v-else class=" px-1">
        <li v-for="(item, index) in displayedItems" :key="index" class="p-1 flex border-b border-slate-200">
          {{ item.name }}
        </li>
      </ul>

    </div>
    <div class="mt-4 flex justify-between items-center">
        <!-- :disabled="currentPage === 1" -->
      <button
        @click="previousPage"
        :class="[currentPage === 1 ? 'opacity-50' : '']"
        class="px-2 py-1 rounded-md border border-gray-300 bg-white shadow-sm "
      >
        <i class="fa-solid fa-arrow-left-long"></i>
      </button>
      <div class="flex flex-col ">
        <span class="text-sm">
          Page {{ currentPage }} of {{ totalPages }}
        </span>
        <button @click.prevent="showMore" v-if="showMoreAvailable && show_more" class="text-sm text-blue-500">
          Show More
        </button>

      </div>
      <button
        @click="nextPage"
        :class="[currentPage === totalPages ? 'opacity-50' : '']"
        class="px-2 py-1 rounded-md border border-gray-300 bg-white shadow-sm "
      >
        <i class="fa-solid fa-arrow-right-long"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    itemsPerPage: Number,
    entityType: String,
    is_grid: Boolean,
    grid_cols: String,
    has_refresh: Boolean,
    show_more: Boolean
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      currentItemsPerPage: this.itemsPerPage,
    };
  },
  computed: {
    filteredItems() {
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return this.items.filter(item => item.name.toLowerCase().includes(query));
      } else {
        return this.items;
      }
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.currentItemsPerPage);
    },
    displayedItems() {
      const startIndex = (this.currentPage - 1) * this.currentItemsPerPage;
      const endIndex = startIndex + this.currentItemsPerPage;
      return this.filteredItems.slice(startIndex, endIndex);
    },
    showMoreAvailable() {
      const nextPageEndIndex = this.currentItemsPerPage + this.itemsPerPage;
      return nextPageEndIndex < this.filteredItems.length;
    },
  },
  watch: {
    searchQuery: {
      handler() {
        this.currentPage = 1; // Reset currentPage when searchQuery changes
      },
      immediate: true,
    },
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    showMore() {
      const nextPageEndIndex = this.currentItemsPerPage + this.itemsPerPage;
      if (nextPageEndIndex <= this.filteredItems.length) {
        this.currentItemsPerPage = nextPageEndIndex;
      } else {
        this.currentItemsPerPage = this.filteredItems.length; // Show remaining items
        this.showMoreAvailable = false; // No more items to show
      }
    },

  },
};
</script>
