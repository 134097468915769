import Api from "@/services/Api";

export default {
    async getDepartments(vendor_id) {
        var departments = []
        await Api(true).post('department/list/' + vendor_id, {})
        .then(r => {
            departments = r.data
        })
        return departments
    },

    async getDepartmentsList() {
        var departments = []
        await Api(true).post('department/list', {})
        .then(r => {
            departments = r.data
        })
        return departments
    },

    async createDepartment(department, vendor_id) {
        await Api(true).post('department/create/' + vendor_id, department)
        .then(r => {
            department = r.data
        })
        return department
    },

    async editDepartment(department, vendor_id) {
        await Api(true).post('department/edit/' + vendor_id, department)
        .then(r => {
            department = r.data
        })
        return department
    }
};
