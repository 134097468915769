<template>
  <div>
    <div class="is-flex bg-slate-200 px-2 py-1 title is-size-5 mb-1">
      Kompania
    </div>
    <div>
      <div class="tile is-ancestor mt-0">
        <div class="tile is-parent is-3">
          <article class="tile is-child box">
            <p class="title is-size-5 mb-3">
              Detajet e kompanise
              <a @click.prevent="show_krudh('configuration')" class="is-pulled-right text-sm" href="">
                <i class="fa-solid fa-gears mr-1"></i>
              </a>
            </p>
            <div class="">
              <table class="table is-striped is-fullwidth is-narrow is-bordered">
                <tbody>
                  <tr>
                    <td> <span>Emri i kompanise </span> </td>
                    <td class="has-text-right font-bold"> <span>{{ get_configuration_value('COMPANY_NAME') }}</span> </td>
                  </tr>
                  <tr>
                    <td> <span>Pershkrimi </span> </td>
                    <td class="has-text-right font-bold">
                      <span>{{ get_configuration_value('COMPANY_DESCRIPTION') }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td> <span>Adresa </span> </td>
                    <td class="has-text-right font-bold"> <span>{{ get_configuration_value('COMPANY_ADDRESS') }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td> <span>Logo </span> </td>
                    <td class="has-text-right font-bold"> <span>{{ get_configuration_value('COMPANY_LOGO') }}</span> </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </article>
        </div>
        <div class="tile is-parent is-4">
          <article class="tile is-child box">
            <p class="title is-size-5 mb-3">Pagesa</p>
            <!-- <div class="is-flex is-justify-content-space-evenly text-sm">
                            <a @click.prevent="show_payment_terms_krudh('payment_terms')"
                                class="py-2 px-3 bg-sky-100" href="">Termat e pageses
                            </a>
                            <a @click.prevent="show_payment_methods_krudh('payment_methods')" 
                                class="py-2 px-3 bg-sky-100" href="">
                                Format e pageses
                            </a>
                        </div> -->
            <div class="is-flex is-flex-direction-column">
              <div class="">
                <p class="m-0 is-flex bg-gradient-to-r from-slate-50 to-slate-200 p-1">
                  <span> Termat e pageses </span>
                  <a @click.prevent="show_payment_terms_krudh('payment_terms')" class="ml-auto mr-1">
                    <i class="fa-solid fa-gears "></i>
                  </a>
                </p>
                <table class="table is-striped is-fullwidth is-narrow is-bordered">
                  <tbody>
                    <tr v-for="pt in payment_terms" :key="pt.guid">
                      <td>{{ pt.name }}</td>
                      <td>{{ pt.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class=" mt-3">
                <p class="m-0 is-flex bg-gradient-to-r from-slate-50 to-slate-200 p-1">
                  <span>Format e pageses</span>
                  <a @click.prevent="show_payment_methods_krudh('payment_methods')" class="ml-auto mr-1">
                    <i class="fa-solid fa-gears "></i>
                  </a>
                </p>
                <table class="table is-striped is-fullwidth is-narrow is-bordered">
                  <tbody>
                    <tr v-for="pm in payment_methods" :key="pm.guid">
                      <td>{{ pm.name }}</td>
                      <td>{{ pm.description }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </article>
        </div>
        <div class="tile is-parent">
          <article class="tile is-child box">
            <p class="title is-size-5 mb-3">Marka
              <a @click.prevent="show_brands_krudh('brands')" class="is-pulled-right text-sm" href="">
                <i class="fa-solid fa-gears mr-1"></i>
              </a>
            </p>

            <simple-pagination :items="brands_list" :itemsPerPage="4" :is_grid="true" :grid_cols="'grid-cols-4'"
              :show_more="true"></simple-pagination>

            <!-- Backup working -->
            <!-- <div class="grid grid-cols-5 gap-2">
                            <div v-for="brand in brands_list" :key="brand.guid" class="box mb-0 p-2">
                                <div class="w-full has-text-centered">
                                    <figure class="image is-128x128 m-auto" style="width:100%; height:auto;">
                                        <img src="https://bulma.io/images/placeholders/128x128.png">
                                    </figure>
                                <p class="subtitle is-size-6 has-text-centered my-2">{{brand.name}}</p>
                                </div>
                            </div>
                        </div> -->

          </article>
        </div>
      </div>
      <div class="tile is-ancestor mt-0">
        <div class="tile is-parent is-3">
          <article class="tile is-child box">
            <p class="title is-size-5 mb-3">
              Departamente
              <a @click.prevent="show_departments_krudh('departments')" class="is-pulled-right text-sm" href="">
                <i class="fa-solid fa-gears mr-1"></i>
              </a>
            </p>
            <div class="uppercase border-b border-gray-100 hover:bg-gray-100" v-for="department in departments" :key="department.guid">
              {{ department.name }}
            </div>
          </article>
        </div>
        <div class="tile is-parent is-4">
          <article class="tile is-child box">
            <p class="title is-size-5 mb-3">
              Garanci
              <a @click.prevent="show_warranties_krudh('warranties')" class="is-pulled-right text-sm" href="">
                <i class="fa-solid fa-gears mr-1"></i>
              </a>
            </p>
            <div class="">
              <simple-pagination :items="warranties_list" :itemsPerPage="4" :has_refresh="true"
                @refresh_data="get_warranties_list"></simple-pagination>
            </div>
          </article>
        </div>
        <div class="tile is-parent is-5">
          <article class="tile is-child box">
            <p class="title is-size-5 mb-3">
              Arsyet per refuzumun e shitjes
              <a @click.prevent="show_rejecton_reasons_krudh('rejecton_reasons')" class="is-pulled-right text-sm" href="">
                <i class="fa-solid fa-gears mr-1"></i>
              </a>
            </p>
            <div class="border-b border-gray-100 hover:bg-gray-100" v-for="reason in rejecton_reasons" :key="reason.guid">
              {{ reason.text }}
            </div>
          </article>
        </div>
      </div>
    </div>
    <br>
  </div>
</template>

<script>
import { $vfm } from 'vue-final-modal'
import { defineAsyncComponent } from "vue"
import ConfigurationService from "@/services/Configuration.js"
import ProductsServices from "@/services/Product";
import DepartmentsServices from "@/services/Department";
import { useKrudhStore } from "@/stores/krudh_communication";
import { storeToRefs } from "pinia";
import simplePagination from "@/components/simple_pagination.vue";
// import DaForm from "@/components/daform/index.vue";
export default {
  components: {
    simplePagination
  },
  data() {
    return {
      form: null,
      configurations_data: [],
      // payment_methods: [],
      // payment_terms: [],
      krudh_communication_data: storeToRefs(useKrudhStore()),
      payments_data: [],
      brands_list: [],
      warranties_list: [],
      departments: [],
      rejecton_reasons: [],


    }
  },
  created() {
    this.get_company_data()
  },
  methods: {
    async get_company_data() {
      this.configurations_data = await ConfigurationService.getConfigurations()
      this.payments_data = await ConfigurationService.getPaymentsConfiguration()
      this.brands_list = await ConfigurationService.getBrandsList()
      this.departments = await DepartmentsServices.getDepartmentsList()
      // get rejection_reasons ...
      this.rejecton_reasons = await ConfigurationService.getRejectionReasons()
      await this.get_warranties_list();

    },
    async get_warranties_list() {
      this.warranties_list = await ProductsServices.getWarranties()
    },
    show_krudh() {
      var params = {
        fields: [
          'config_key:Titulli i komfigurimit,config_value:Vlera e konfigurimit'
          // 'icon:Ikona,*name:Emri', 
          // 'description:Pershkrimi'
        ],
        entity: 'configurations',
        columns: ['icon:80px', 'name:130px'],
        title: 'Konfigurime'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_brands_krudh(entity) {
      var params = {
        fields: [
          'name:Emri i markes'
          // 'icon:Ikona,*name:Emri', 
          // 'description:Pershkrimi'
        ],
        sort: 'name',
        entity: entity,
        columns: ['name:80%'],
        title: 'Marka'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_payment_methods_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'description:Pershkrimi'
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Format e pageses'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_payment_terms_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'description:Pershkrimi'
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Termat e pageses'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    get_configuration_value(key) {
      var text = ""
      this.configurations_data.map(c => {
        if (c.config_key == key) return text = c.config_value
      })
      return text
    },
    show_departments_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'description:Pershkrimi'
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Departamente'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_warranties_krudh(entity) {
      var params = {
        fields: [
          'name:Emri',
          'sort:Renditja',
          'is_default:Default:checkbox-field',
          // 'icon:Ikona,*name:Emri', 
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Garancite',
        sort: 'sort',
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    show_rejecton_reasons_krudh(entity) {
      var params = {
        fields: [
          'text:Shpjegimi',
        ],
        entity: entity,
        columns: ['name:80%'],
        title: 'Arsyet e refuzimit te shitjes'
      }
      $vfm.show({
        component: defineAsyncComponent(
          () => import("@/components/krudh-modal.vue")
        ), params
      })
    },
    // show_sellers_krudh(entity){
    //   var params = {
    //     fields: [
    //       'name:Emri',
    //     ],
    //     entity: entity,
    //     columns: ['name:80%'],
    //     title: 'LIsta e shitesve te produkteve'
    //   }
    //   $vfm.show({
    //     component: defineAsyncComponent(
    //       () => import("@/components/krudh-modal.vue")
    //     ), params
    //   })
    // }


  },
  watch: {
    krudh_communication_data: {
      async handler(n) {
        if (n.entity == 'payment_terms' || n.entity == 'payment_methods') {
          // Update Payments
          this.payments_data = await ConfigurationService.getPaymentsConfiguration()
        }
        else if (n.entity == 'configurations') {
          // Update configurations
          this.configurations_data = await ConfigurationService.getConfigurations()
        }
        else if (n.entity == 'brands') {
          this.brands_list = await ConfigurationService.getBrandsList()
        }

        // Reset store variables
        this.krudh_communication_data.response = [],
          this.krudh_communication_data.entity = '',
          this.krudh_communication_data.is_new = false
      },
      deep: true
    }
  },
  computed: {
    payment_methods() {
      var a = []
      if (this.payments_data.payment_methods) a = this.payments_data.payment_methods
      return a
    },
    payment_terms() {
      var a = []
      if (this.payments_data.payment_terms) a = this.payments_data.payment_terms
      return a
    }
  }
}
</script>

<style></style>
